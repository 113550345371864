import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import renderMarkdown from '../PageBuilder/markdownProcessor.js';

import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';

import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  Avatar,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import SectionHero from './SectionHero';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';

import css from './ListingPage.module.css';
import { listingTypeEnum, listingFieldEnum } from '../../config/configListingEnums';
import loadable from '@loadable/component';
import SectionGallery from './SectionGallery';
import {hexToFilter} from "../../styles/hexToFilter";
import {useCSSVariable} from "../../styles/useCSSVariable";
const ShareModal = loadable(() =>
  import(/* webpackChunkName: "ShareModal" */ '../../components/ShareModal/ShareModal')
);

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const marketplaceColor = useCSSVariable('--marketplaceColor', '#6c3a87');

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;
  const [modalIsShown, setModalIsShown] = useState(false);

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const markDownDescription = renderMarkdown(description);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };
  // share modal handler
  const showModalHandler = () => {
    setModalIsShown(true);
  };
  const closeModalHandler = () => {
    setModalIsShown(false);
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const handleViewPhotosClick = e => {
    // Stop event from bubbling up to prevent image click handler
    // trying to open the carousel as well.
    e.stopPropagation();
    setImageCarouselOpen(true);
  };
  const eventLatitude = currentListing.attributes.geolocation.lat;
  const eventLongitude = currentListing.attributes.geolocation.lng;
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${eventLatitude},${eventLongitude}`;
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const today = days[new Date().getDay()];
  const website = publicData[listingFieldEnum.WEBSITE];
  const phone_number = publicData[listingFieldEnum.PHONE_NUMBER];
  const emailAddress = publicData[listingFieldEnum.EMAIL];
  const upcomingDates = publicData[listingFieldEnum.START_DATE_TIME];
  const filter = hexToFilter(marketplaceColor);

  function getDealsContainer(layout) {
    return publicData[listingFieldEnum.DEALS] && (
      <div className={`${css.descriptionContainer} ${css.individualContainer} ${layout === 'MOBILE' ? css.mobileDealsContainer : css.desktopDealsContainer}`}>
        <div className={css.cardLabels}>
          <img
            src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/comment-dollar.svg"
            style={{
              height: '1em', width: '1em', marginRight: '5px',
              // // #419180
              // filter: 'invert(52%) sepia(22%) saturate(872%) hue-rotate(117deg) brightness(92%) contrast(87%)',
              // white
              filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
            }}
          />
          <h2 className={css.listingPageDescriptionTitle}>
            <FormattedMessage id="ListingPage.dealsTitle" />
          </h2>
        </div>
        <SectionTextMaybe markdownComponents={renderMarkdown(publicData[listingFieldEnum.DEALS])} showAsIngress />
      </div>
    );
  }

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <SectionHero
          title={title}
          listing={currentListing}
          isOwnListing={isOwnListing}
          editParams={{
            id: listingId.uuid,
            slug: listingSlug,
            type: listingPathParamType,
            tab: listingTab,
          }}
          imageCarouselOpen={imageCarouselOpen}
          onImageCarouselClose={() => setImageCarouselOpen(false)}
          handleViewPhotosClick={handleViewPhotosClick}
          onManageDisableScrolling={onManageDisableScrolling}
        />
        {/* Main Container */}
        <div className={css.listingPageContainer}>
          {modalIsShown && <ShareModal onClose={closeModalHandler} />}
          <div className={css.listingPageTopContainer}>
            <ul className={css.listingPageActions}>
              {publicData.listingType !== listingTypeEnum.GENERAL_BUSINESS && (
              <li className={css.listingPageActionItems}>
                <img
                  src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/location-pin.svg"
                  style={{height: '1em', width: '1em', marginRight: '5px', filter}}
                />
                <a
                  href={googleMapsUrl}
                  className={css.actionItemLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Directions
                </a>
              </li>
              )}

              {publicData.listingType !== listingTypeEnum.GENERAL_BUSINESS && (
              <li className={css.listingPageActionItems} onClick={showModalHandler}>
                <img
                  src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/share-nodes.svg"
                  style={{height: '1em', width: '1em', marginRight: '5px', filter}}
                />
                <span className={css.actionItemLink}>Share</span>
              </li>
              )}

              {website && (
              <li className={css.listingPageActionItems}>
                <img
                  src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/link.svg"
                  style={{height: '1em', width: '1em', marginRight: '5px', filter}}
                />
                <a
                  href={website}
                  className={css.actionItemLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Website
                </a>
              </li>
              )}
              {publicData[listingFieldEnum.BOOKING_URL] && (
                <li className={css.listingPageActionItems}>
                  <img
                    src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/shop.svg"
                    style={{
                      height: '1em', width: '1em', marginRight: '5px', filter,
                    }}
                  />
                  <a
                    href={publicData[listingFieldEnum.BOOKING_URL]}
                    className={css.actionItemLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div className={css.listingPageColumnContainer}>
            {/* 2 Column Layout 605 / 40% */}
            <div className={css.columnLeft}>
              {/* desription container */}
              <div className={`${css.descriptionContainer} ${css.individualContainer}`}>
                <div className={css.cardLabels}>
                  <img
                    src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/bars.svg"
                    style={{
                      height: '1em', width: '1em', marginRight: '5px', filter,
                    }}
                  />
                  <h2 className={css.listingPageDescriptionTitle}>
                    <FormattedMessage id="ListingPage.eventDescription" />
                  </h2>
                </div>
                <SectionTextMaybe markdownComponents={markDownDescription} showAsIngress />
              </div>
              {/*/!* deals container *!/*/}
              {/*{getDealsContainer('MOBILE')}*/}
              <SectionGallery
                listing={currentListing}
                variantPrefix={config.layout.listingImage.variantPrefix}
              />
              {/* categories container */}
              {listingConfig.listingFields
                .filter(config => {
                  const { includeForListingTypes } = config;
                  const listingType = publicData?.listingType;
                  return (
                    includeForListingTypes == null || includeForListingTypes.includes(listingType)
                  );
                })
                .filter(config => config.key === 'category')
                .map(config => {
                  const { key, enumOptions, scope = 'public' } = config;

                  const value =
                    scope === 'public'
                      ? publicData[key]
                      : scope === 'metadata'
                      ? metadata[key]
                      : null;
                  const hasValue = Array.isArray(value) ? value.length > 0 : value != null;

                  if (config.schemaType === SCHEMA_TYPE_MULTI_ENUM && hasValue) {
                    return (
                      <div className={`${css.categoriesContainer} ${css.individualContainer}`}>
                        <SectionMultiEnumMaybe
                          key={key}
                          heading={config?.showConfig?.label}
                          iconURL="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/list.svg"
                          options={createFilterOptions(enumOptions)}
                          selectedOptions={value || []}
                        />
                      </div>
                    );
                  }

                  return null;
                })}
              {/* tags container */}
              {listingConfig.listingFields
                .filter(config => {
                  const { includeForListingTypes } = config;
                  const listingType = publicData?.listingType;
                  return (
                    includeForListingTypes == null || includeForListingTypes.includes(listingType)
                  );
                })
                .filter(config => config.key === 'tags')
                .map(config => {
                  const { key, enumOptions, scope = 'public' } = config;

                  const value =
                    scope === 'public'
                      ? publicData[key]
                      : scope === 'metadata'
                      ? metadata[key]
                      : null;
                  const hasValue = Array.isArray(value) ? value.length > 0 : value != null;

                  if (config.schemaType === SCHEMA_TYPE_MULTI_ENUM && hasValue) {
                    return (
                      <div className={`${css.categoriesContainer} ${css.individualContainer}`}>
                        <SectionMultiEnumMaybe
                          key={key}
                          iconURL="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/tag.svg"
                          heading={config?.showConfig?.label}
                          options={createFilterOptions(enumOptions)}
                          selectedOptions={value || []}
                        />
                      </div>
                    );
                  }

                  return null;
                })}
            </div>
            <div className={css.columnRight}>
              {/* deals container */}
              {getDealsContainer('DESKTOP')}
              {/* opening hours container */}
              {days.some(day => publicData[`appointment-time-${day}`]) && (
                <div className={css.individualContainer}>
                  <details>
                    <summary className={css.appointmentDateContainer}>
                      <b style={{ color: 'var(--marketplaceColor)' }}>
                        <img
                          src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/clock.svg"
                          style={{ height: '1em', width: '1em', marginRight: '5px', filter }}/>
                        {publicData[`appointment-time-${today}`] ?? 'Opening Hours'}
                      </b>
                      {publicData[`appointment-time-${today}`] && (
                        <span>Open hours today: {publicData[`appointment-time-${today}`]}</span>
                      )}
                    </summary>
                    {days.map(day => {
                      const dayValue = publicData[`appointment-time-${day}`];
                      const hasValue = dayValue != null;
                      const dayLabel = listingConfig.listingFields.find(
                        config => config.key === `appointment-time-${day}`
                      )?.showConfig?.label;
                      if (hasValue) {
                        return (
                          <div className={css.dayDuration}>
                            <span>{dayLabel}</span>
                            <span>{dayValue}</span>
                          </div>
                        );
                      }
                      return null;
                    })}
                    <div className={css.todaysTime}>
                      {new Date().toLocaleString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}{' '}
                      local time
                    </div>
                  </details>
                </div>
              )}
              {/* hosted by container */}
              {publicData.listingType !== listingTypeEnum.PARTNER && (
                <div className={css.individualContainer}>
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.hostedBy" />
                  </H4>
                  <div className={css.hostedPartnerInfo}>
                    <Avatar className={css.avatar} user={currentAuthor} partnerListingID={publicData.partnerListingID} />
                    {publicData?.partnerListingID ? (
                      <NamedLink
                        className={css.link}
                        name="ListingPageCanonical"
                        params={{
                          id: publicData.partnerListingID,
                        }}
                      >
                        {publicData.partnerListingTitle ??
                          currentAuthor.attributes.profile.displayName}
                      </NamedLink>
                    ) : (
                      <NamedLink
                        className={css.link}
                        name="ProfilePage"
                        params={{ id: currentAuthor.id.uuid }}
                      >
                        {currentAuthor.attributes.profile.displayName}
                      </NamedLink>
                    )}
                  </div>
                </div>
              )}
              {upcomingDates && (
                <div className={`${css.datesContainer} ${css.individualContainer}`}>
                  <H4 as="h1" className={css.orderPanelTitle}>
                    <FormattedMessage id="ListingPage.eventDates" />
                  </H4>
                  <div className={css.dates}>{upcomingDates}</div>
                </div>
              )}
              {/* social media container */}
              {(() => {
                const socialProfiles = listingConfig.listingFields.filter(config => {
                  const { key } = config;
                  return key.startsWith('social_media_') && publicData[key];
                });

                // Check if any social media profiles are available
                const hasSocialMediaProfiles = socialProfiles.length > 0;

                // Render social media divs only if profiles are available
                if (hasSocialMediaProfiles) {
                  return (
                    <div className={`${css.socialContainer} ${css.individualContainer}`}>
                      <div className={css.cardLabels}>
                        <img
                          src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/bullhorn.svg"
                          style={{ height: '1em', width: '1em', marginRight: '5px', filter }}
                        />
                        <h2 className={css.listingPageDescriptionTitle}>
                          <FormattedMessage id="ListingPage.socialMediaHeading" />
                        </h2>
                      </div>
                      <ul className={css.social}>
                        <li className={css.socialItems}>
                          {socialProfiles.map(socialProfile => {
                            const {
                              key,
                              showConfig: { label },
                            } = socialProfile;
                            const socialMediaName = key.split('social_media_')[1];
                            return (
                              <a className={css.socialItemsLinks} href={publicData[key]} target="_blank">
                                <span className={css.iconWrapper}>
                                  <img
                                    src={`/static/icons/fontawesome-free-6.5.1-web/svgs/brands/${socialMediaName}.svg`}
                                    alt={socialMediaName}
                                    style={{ width: '1.5rem' }}
                                  />
                                </span>

                                {label}
                              </a>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                  );
                }

                return null; // If no social media profiles are available, render nothing
              })()}
              {/* contact container */}

              {(phone_number || emailAddress) && (
                <div className={`${css.contactContainer} ${css.individualContainer}`}>
                  <div className={css.cardLabels}>
                    <img
                      src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/address-card.svg"
                      style={{ height: '1em', width: '1em', marginRight: '5px', filter }}
                    />
                    <h2 className={css.listingPageDescriptionTitle}>
                      <FormattedMessage id="ListingPage.contactHeading" />
                    </h2>
                  </div>
                  {listingConfig.listingFields.reduce((pickedElements, config) => {
                    const { key, includeForListingTypes, scope = 'public' } = config;
                    const listingType = publicData?.listingType;
                    const isTargetListingType =
                      includeForListingTypes == null ||
                      includeForListingTypes.includes(listingType);

                    const value =
                      scope === 'public'
                        ? publicData[key]
                        : scope === 'metadata'
                        ? metadata[key]
                        : null;
                    const hasValue = Array.isArray(value) ? value.length > 0 : value != null;

                    const isContactField = [
                      listingFieldEnum.PHONE_NUMBER,
                      listingFieldEnum.EMAIL,
                    ].includes(key);

                    if (
                      isTargetListingType &&
                      hasValue &&
                      config.schemaType === SCHEMA_TYPE_TEXT &&
                      isContactField
                    ) {
                      const linkIcon =
                        key === listingFieldEnum.PHONE_NUMBER
                          ? '/static/icons/fontawesome-free-6.5.1-web/svgs/solid/phone.svg'
                          : '/static/icons/fontawesome-free-6.5.1-web/svgs/solid/envelope.svg';

                      const linkUrl =
                        key === listingFieldEnum.PHONE_NUMBER
                          ? `tel:${value}`
                          : `mailto:${value}`;

                      return [
                        ...pickedElements,

                        <div className={css.contactLinks}>
                          <img src={linkIcon} style={{ height: '1em', width: '1em', marginRight: '10px' }} />
                          <a key={key} href={linkUrl} target="_blank" rel="noopener noreferrer"
                             style={{ color: 'black' }}>
                            {value}
                          </a>
                        </div>,
                      ];
                    } else {
                      return pickedElements;
                    }
                  }, [])}
                </div>
              )}
              {/* Region */}
              {listingConfig.listingFields.reduce((pickedElements, config) => {
                const { key, includeForListingTypes, scope = 'public' } = config;
                const listingType = publicData?.listingType;
                const isTargetListingType =
                  includeForListingTypes == null || includeForListingTypes.includes(listingType);

                const value =
                  scope === 'public'
                    ? publicData[key]
                    : scope === 'metadata'
                    ? metadata[key]
                    : null;
                const hasValue = Array.isArray(value) ? value.length > 0 : value != null;

                return isTargetListingType &&
                  hasValue &&
                  config.schemaType === SCHEMA_TYPE_TEXT &&
                  key === 'region'
                  ? [
                      ...pickedElements,
                      <div className={`${css.regionContainer} ${css.individualContainer}`}>
                        <SectionTextMaybe
                          key={key}
                          iconURL="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/globe.svg"
                          heading={config?.showConfig?.label}
                          text={value}
                        />
                      </div>,
                    ]
                  : pickedElements;
              }, [])}

              {/* map */}
              <div className={`${css.mapContainer} ${css.individualContainer}`}>
                <SectionMapMaybe
                  geolocation={geolocation}
                  iconURL="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/map.svg"
                  publicData={publicData}
                  listingId={currentListing.id}
                  mapsConfig={config.maps}
                  mapIconURL={ensuredAuthor?.profileImage?.attributes?.variants?.['square-small']?.url}
                />
                {/* <p className={css.mapAddress}>{eventAddress}</p> */}
              </div>
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
